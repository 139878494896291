<template>
  <div v-loading="loading">
    <section>
      <article class="mt-5">
        <div class="container mt-5">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/users' }">Users</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>

          <h3><b>Support Team:</b></h3>
          <div
            style="display: flex; align-items:center; justify-content: space-between;"
            class="search_add_section"
          >
            <div style="width: 50%;">
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by Name"
                class="search_by_input form-control"
                spellcheck="off"
              />
            </div>
            <div>
              <AddUserDialog @re-fresh="getUsers" />
            </div>
          </div>
          <hr />

          <div
            v-loading="loading || is_changing"
            class="table-responsive mb-5"
          >
            <data-tables
              :data="users"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="isLoading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getUsers"
                  v-else-if="!isLoading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Admin Users Now. Please click here to retry
                </div>
                <div v-if="!isLoading && !loadingError">No Admin Users</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Photo"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="75px"
              >
                <template slot-scope="scope">
                  <img
                    :src="scope.row.profile_picture"
                    style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover;"
                  >
                </template>
              </el-table-column>

              <el-table-column
                label="User Info"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.first_name }} {{ scope.row.last_name }} </span><br />
                  <strong>{{ scope.row.email }}</strong><br />
                  <span v-if="$store.state.currentRole == 'ADMIN'">
                    <strong>Password:</strong> {{ scope.row.password }}
                  </span><br />
                  <small><strong>Added On:</strong> {{ formattedDate(scope.row.created_date) }} </small><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Gender"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <span> {{ scope.row.gender }} </span><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Role"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <span style="color:green">
                    <small
                      class="text-success"
                      v-if="scope.row.role == 'ADMIN'"
                    >Admin</small>
                    <small
                      class="text-success"
                      v-else
                    >Support</small>
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="Approval"
                :show-overflow-tooltip="true"
                label-class-name="text-center"
                class-name="text-center"
                width="auto"
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.is_approved == 'APPROVED'"
                    style="color: green; font-size: 12px; font-weight: 500;"
                  > <i class="el-icon-check"></i> Approved</span>
                  <span
                    v-if="scope.row.is_approved == 'REJECTED'"
                    style="color: red; font-size: 12px; font-weight: 500;"
                  > <i class="el-icon-close"></i> Rejected</span>
                  <span
                    v-if="scope.row.is_approved == 'PENDING'"
                    style="color: orange; font-size: 12px; font-weight: 500;"
                  > <i class="el-icon-alarm-clock"></i> Pending</span>


                  <br/>

                  
                  <el-select
                    v-model="scope.row.is_approved"
                    placeholder="Select"
                    size="mini"
                    @change="changeIsApprovedStatus(scope.row.user_id, scope.row.is_approved)"
                  >
                    <el-option
                      v-for="item in approval_statuses"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>

                </template>
              </el-table-column>

              <el-table-column
                label="Status"
                label-class-name="text-center"
                class-name="text-center"
                width="100px"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex;">
                    <el-tooltip
                      :content="scope.row.is_active ? 'Active' : 'Deactivated'"
                      placement="top"
                    >
                      <el-switch
                        v-model="scope.row.is_active"
                        @change="switchStatus(scope.row.is_active, scope.row.user_id)"
                        active-color="#13ce66"
                        inactive-color=""
                      >
                      </el-switch>
                    </el-tooltip>
                  </el-row>

                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-tooltip
                      content="Edit"
                      placement="top"
                    >
                      <el-button
                        type="warning"
                        :disabled="$store.state.userRoles !== 'ADMIN'"
                        @click="showEditUserData(scope.row)"
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Delete"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        :disabled="$store.state.userRoles !== 'ADMIN'"
                        @click="deleteUser(scope.row.user_id)"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>

    <EditUserDialog
      :showUserEditDialog="showUserEditDialog"
      :userData="user_data"
      @closeEditUserDialog="closeEditUserData"
      @re-fresh="getUsers"
    />

  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import AddUserDialog from "../components/dialogs/add-user-dialog.vue";
import EditUserDialog from "../components/dialogs/edit-user-dialog.vue";
import { format, parseISO } from "date-fns";
export default {
  components: {
    ScaleOut,
    AddUserDialog,
    EditUserDialog,
  },

  data() {
    return {
      loading: false,
      isLoading: false,
      is_changing: false,
      showUserEditDialog: false,
      users: [],
      user_data: {},

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "fullname",
          value: "",
        },
      ],
      loadingError: false,
      

      approval_statuses: [
        {
          value: "PENDING",
          label: "Pending",
        },
        {
          value: "APPROVED",
          label: "Approved",
        },
        {
          value: "REJECTED",
          label: "Rejected",
        }
      ],

    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
  },

  mounted() {
    this.getUsers();
  },

  methods: {
    showEditUserData(userData) {
      this.showUserEditDialog = true;
      this.user_data = userData;
    },

    closeEditUserData() {
      this.showUserEditDialog = false;
    },

    async getUsers() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        const token = JSON.parse(localStorage.getItem("user"));
        let request = await this.$http.get(`users`);
        if (request.data.success) {
          this.users = request.data.users;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Users now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async deleteUser(userId) {
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this Admin User. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.delete(`users/${userId}`);
            if (
              request.data.success &&
              request.data.message === "USER_DELETED_SUCCESSFULLY"
            ) {
              this.getUsers();
              return this.$notify({
                title: "Success",
                message: "Admin User deleted successfully!",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            return this.$notify({
              title: "Unable to Delete",
              message: "Unable to delete Admin User, Please try again",
              type: "error",
            });
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {
          //
        });
    },

    async switchStatus(activation_status, userId) {
      try {
        this.is_changing = true;
        let request = await this.$http.patch(
          `users/change_is_active_status/${userId}`, {
            is_active: activation_status,
          }
        );
        if (
          request.data.success &&
          request.data.message == "USER_IS_ACTIVE_STATUS_CHANGED_SUCCESSFULLY"
        ) {
          this.getUsers();
          this.showSuccessMessage("Success", );
              return this.$notify({
                title: "Success",
                message: "User status changed",
                type: "success",
              });
        } else if (
          request.data.message == "ONLY_APPROVED_STAFF_CAN_BE_ACTIVATED"
        ) {
          this.showWarningMessage(
            "Approve First",
            "Only Approved Staff Members can be activated/deactivated. Please approve them first."
          );
          this.is_changing = false;
          console.log(this.is_active);
          // this.is_active = false;
        } else {
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.is_changing = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to change staff status",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.is_changing = false;
      }
    },

    async changeIsApprovedStatus(userId, is_approved_status) {
      try {
        this.is_changing = true;
        let request = await this.$http.patch(
          `users/change_is_approved_status/${userId}`, {
            is_approved_status: is_approved_status,
          }
        );
        if (
          request.data.success &&
          request.data.message == "USER_IS_APPROVED_STATUS_CHANGED_SUCCESSFULLY"
        ) {
          this.getUsers();
          this.showSuccessMessage("Success", );
              return this.$notify({
                title: "Success",
                message: "User status changed",
                type: "success",
              });
        } else if (
          request.data.message == "ONLY_APPROVED_STAFF_CAN_BE_ACTIVATED"
        ) {
          this.showWarningMessage(
            "Approve First",
            "Only Approved Staff Members can be activated/deactivated. Please approve them first."
          );
          this.is_changing = false;
          console.log(this.is_active);
          // this.is_active = false;
        } else {
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.is_changing = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to change staff status",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.is_changing = false;
      }
    }

  },
};
</script>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>